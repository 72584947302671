import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import Newscontainer from '../Components/Newscontainer';
import "./../App.css";

export default function News() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
      <Header id={9}/>
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover  brightness-90 saturate-100 "
              src={require("../../src/Assets/images/News.jpeg")}
            ></img>
            <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4  gap-4">
              <div
                className=" font-opensans font-bold text-6xl text-left "
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                News
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem] text-left">
              At Qulabs, we are constantly in the process of rediscovering possibilities and reimagining boundaries using the power of quantum communications and the innovations that we deliver as a result of the same. The IPs that we own are intended to protect leading edge technologies that we produce through our R&D efforts and designs. 
              </p>
            </div>
          </div>
        }
      />
      <p className="w-full text-center font-opensans font-extrabold 2xl:text-3xl md:text-2xl text-[#E7EAEE] mt-10">
          News
        </p>
      <div className="mt-3 w-full h-auto relative flex items-center justify-center px-5 mb-10 gap-8 ">
           <Newscontainer/>  
      </div>
      <Footer showpage={false}/>
      </div>
      
  );
}
