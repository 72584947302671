import React, { useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { newsdata } from "../Data/News";
import Newscard from "./Newscard";

export default function Newscontainer() {
  const sliderRef = useRef(null);
  

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -260,
        behavior: "smooth",
      });
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 260,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative w-full ">
      <div className="flex items-center justify-center md:gap-6 gap-2">
      <div
          className="md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-full bg-[#0C2E52] hover:bg-[#0C2E52]/60 flex items-center justify-center cursor-pointer"
          onClick={slideLeft}
        >
          <FaChevronLeft
            color="#1B99D4"
            size={20}
            className="opacity-50 cursor-pointer hover:opacity-100 hidden md:block"
          />
          <FaChevronLeft
            color="#1B99D4"
            size={12}
            className="opacity-50 cursor-pointer hover:opacity-100 block md:hidden"
          />
        </div>

        <div
          id="slider"
          ref={sliderRef}
          className="flex items-center gap-6  overflow-x-auto scrollbar-hide scroll-smooth w-4/5 md:p-4 "
        >
          {newsdata.map((item) => (
                <div key={item.id} className="w-auto h-auto cursor-pointer" >
                  <Newscard
                    img={item.img}
                    name={item.name}
                    content={item.content}
                    link={item.link}
                    onReadMoreClick={(data) => console.log('Read more clicked:', data)}
                  />
                </div>
              ))}
        </div>

        <div
          className="md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-full bg-[#0C2E52] hover:bg-[#0C2E52]/60 flex items-center justify-center cursor-pointer"
          onClick={slideRight}
        >
          <FaChevronRight
            color="#1B99D4"
            size={20}
            className="opacity-50 cursor-pointer hover:opacity-100 hidden md:block"
          />
           <FaChevronRight
            color="#1B99D4"
            size={12}
            className="opacity-50 cursor-pointer hover:opacity-100 block md:hidden"
          />
        </div>
      </div>
      
    </div>
  );
}
